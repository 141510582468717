<template>
  <v-card>
    <v-card-title :class="$style.logoWrapper" class="d-flex flex-nowrap px-6">
      <v-img v-if="partnerLogo" :src="partnerLogo" :class="$style.partnerLogo" contain></v-img>
      <v-spacer v-if="partnerLogo" />
      <v-img v-if="companyLogo" :src="companyLogo" :class="$style.companyLogo" contain></v-img>
    </v-card-title>

    <v-card-text class="px-6 pb-0">
      <v-form
        ref="loginForm"
        id="loginForm"
        name="loginForm"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
        :disabled="loading || disabled"
      >
        <v-text-field
          :class="errorMessages.length ? 'mb-2' : 'mb-6'"
          v-model="login"
          :rules="emailRules"
          required
          outlined
          dense
          :label="$vuetify.lang.t('$vuetify.login')"
          :placeholder="$vuetify.lang.t('$vuetify.loginForm.emailPlaceholder')"
          autocomplete="email"
          name="email"
          type="email"
          :error-messages="errorMessages"
          hide-details="auto"
          @focus="errorMessages = []"
        />

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          required
          outlined
          dense
          id="password"
          label="Password"
          placeholder="***"
          autocomplete="password"
          name="password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @focus="errorMessages = []"
        />
      </v-form>
    </v-card-text>

    <v-card-actions class="px-6 pt-0">
      <v-checkbox v-model="remember" label="Remember me" color="primary" hide-details></v-checkbox>

      <v-spacer />

      <v-btn
        class="px-4 py-3"
        color="primary"
        type="submit"
        form="loginForm"
        :loading="loading"
        :disabled="!valid || loading"
        >{{ 'Login' }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import STATES from '../../../store/actions/states';
import { AUTH_REQUEST } from '../../../store/actions/auth';
import { StringTests, ErrorType, ErrorMessage, ErrorMessages } from '../../../constants';
import { captureException } from '@sentry/vue';

const companyLogo = process.env.VUE_APP_COMPANY_LOGO;
const partnerLogo = process.env.VUE_APP_PARTNER_LOGO;

export default {
  name: 'LoginForm',

  data() {
    return {
      companyLogo,
      partnerLogo,
      valid: true,
      login: process.env.VUE_APP_USERLOGIN,
      emailRules: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.login_required'),
        (v) => StringTests.email.test(v) || this.$vuetify.lang.t('$vuetify.alerts.login_not_valid'),
      ],
      password: process.env.VUE_APP_USERPASS,
      passwordRules: [(v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.password_required')],
      showPassword: false,
      disabled: false,
      errorMessages: [],
      remember: false,
    };
  },

  computed: {
    ...mapGetters(['authStatus']),
    loading() {
      return this.authStatus === STATES.LOADING;
    },
    watchSubmission() {
      return this.loading || this.disabled;
    },
  },
  methods: {
    async submit() {
      if (this.$refs.loginForm.validate()) {
        this.disabled = true;
        const { login, password, remember } = this;

        try {
          let payload = {
            login,
            password,
            remember,
            errorHandler: this.errorHandler,
          };

          if (this.enableRecaptcha) {
            payload.RC_ResponseToken = await this.recaptchaToken('login');
          }

          const result = await this.$store.dispatch(AUTH_REQUEST, payload);

          this.handleAuthentication();
        } catch (error) {
          this.disabled = false;
          captureException(error);
          this.$log.info('Some error while handling this request, please refresh your page and try again.');
        }
      }
    },

    errorHandler(error) {
      if (error) {
        const { config, request, response, isAxiosError } = error;

        if (isAxiosError) {
          if (typeof response !== 'undefined') {
            let { data } = response;
            let { type, message } = data;

            if ([ErrorType.WS_ERROR, ErrorType.SERVICE_ERROR].includes(type) && message) {
              const regex = /(?<message>[\w]+)([:#\s]+(?<code>\w)+)?/gm;
              const match = regex.exec(message);

              if (match.length > 1 && match.groups.message) {

                if (match.groups.message === ErrorMessage.INVALID_LOGIN_OR_PASSWORD) {
                  const msg = this.$vuetify.lang.t('$vuetify.alerts.invalid_credentials');

                  this.errorMessages.push(msg);

                  this.$log.debug(this.errorMessages);
                  return false;
                }
              }
            }

            if (type === ErrorType.AUTH_ERROR && message === ErrorMessages.NOT_VERIFIED) {
              const msg = this.$vuetify.lang.t('$vuetify.alerts.user_not_verified');
              this.errorMessages.push(msg);
              return false;
            }
          }
        }
      }
      return 'LoginForm Error';
    },

    handleAuthentication() {
      let { redirect } = this.$route.query;

      if (redirect) {
        this.$router.push({ path: redirect });
      } else {
        this.$router.push({ path: process.env.VUE_APP_DEFAULT_REDIRECT });
      }
    },

    submissionWatcher(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('change:submission', val);
      }
    },
  },

  watch: {
    watchSubmission: {
      immediate: true,
      handler: 'submissionWatcher',
    },
  },
};
</script>

<style lang="scss" module>
.logoWrapper {
  display: flex;
}

.partnerLogo {
  max-width: 100px;
}

.logo {
  max-height: 60px;
}

.spacer + .companyLogo {
  max-width: calc(100% - 100px);
}
</style>
