<script>
import LoginForm from '../components/LoginForm.vue';

const bgImageName = process.env.VUE_APP_BG;

export default {
  name: 'LoginView',

  metaInfo: {
    title: 'Login',
  },

  components: {
    LoginForm,
  },

  data() {
    return {
      bgImage: bgImageName,
    };
  },
};
</script>

<template>
<v-parallax
  dark
  :class="$style.loginView"
  class="fill-height"
  height="100%"
  :src="require(`@/assets/bg/${bgImage}.jpg`)"
  :srcset="require(`@/assets/bg/${bgImage}@2.jpg`) + ' 2x'"
  fluid>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12"
      sm="8"
      md="4"
      xl="3"
      offset-xl="7">

    <login-form></login-form>

    </v-col>
  </v-row>
</v-parallax>
</template>


<style module>
.loginView {
  background-size: cover;
}
</style>
